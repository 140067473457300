<template>
  <!--
    The view for the Skimovie, Speedcheck, PhotoStart, PhotoPoint and TimeMeasurement-components.
    Used to display the sessions of the installations spots.
  -->
  <div
    v-if="isInstallationTypeAvailable"
    class="installationDetail_Session"
  >
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Skimovie
        v-if="showSkimovieRuns"
        :installation-id="installationId"
        :installation-type="getInstallationType"
      />

      <Speedcheck
        v-else-if="showSpeedcheckRuns"
        :installation-id="installationId"
        :installation-type="getInstallationType"
        :installation-country="installationCountry"
      />

      <PhotoStart
        v-else-if="showPhotoStart"
        :installation-id="installationId"
        :installation-type="getInstallationType"
      />

      <PhotoTrap
        v-else-if="showPhotoTrap"
        :installation-id="installationId"
        :installation-type="getInstallationType"
      />

      <PhotoPoint
        v-else-if="showPhotoPoint"
        :installation-id="installationId"
        :installation-type="getInstallationType"
      />

      <TimeMeasurement
        v-else-if="showTimeMeasurement"
        :installation-id="installationId"
        :installation-type="getInstallationType"
      />
        
      <p v-else>
        {{ $t('noDataAvailable') }}
      </p>

      <DatabaseAuditLogList
        v-if="authenticationHasRole('moderation')"
        ref="databaseAuditLogList"
        :is-session-delete="true"
        :installation-id="installationId"
      />
    </template>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "InstallationDetailSession",
  components: {
    Skimovie: () => import('@/components/Media/Skimovie.vue'),
    Speedcheck: () => import('@/components/Media/Speedcheck.vue'),
    PhotoStart: () => import('@/components/Media/PhotoStart.vue'),
    PhotoPoint: () => import('@/components/Media/PhotoPoint.vue'),
    PhotoTrap: () => import('@/components/Media/PhotoTrap.vue'),
    TimeMeasurement: () => import('@/components/Media/TimeMeasurement.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  mixins: [
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      installationObject: null,
      loading: true,
    }
  },
  metaInfo () {
    return {
      title: this.$t('installationDetailSessionView.sessions')
    }
  },
  computed: {
    isInstallationTypeAvailable: function () {
      if (this.installationObject == null) {
        return false;
      }
      if (!this.installationObject.installationType) { return false; }
      return true;
    },
    getInstallationType: function () {
      return this.installationObject.installationType.trim().toLowerCase();
    },
    showSkimovieRuns: function () {
      let type = this.getInstallationType;
      let allowedTypes = [ 'skimovie', 'biketrack', "eventmovie" ];
      return allowedTypes.includes(type);
    },
    showSpeedcheckRuns: function () {
      let type = this.getInstallationType;
      let allowedTypes = [ 'speedcheck' ];
      return allowedTypes.includes(type);
    },
    showPhotoStart: function () {
      let type = this.getInstallationType;
      let allowedTypes = [ 'photostart' ];
      return allowedTypes.includes(type);
    },
    showPhotoTrap: function () {
      let type = this.getInstallationType;
      let allowedTypes = [ 'phototrap' ];
      return allowedTypes.includes(type);
    },
    showPhotoPoint: function () {
      let type = this.getInstallationType;
      let allowedTypes = [ 'photopoint' ];
      return allowedTypes.includes(type);
    },
    showTimeMeasurement: function () {
      let type = this.getInstallationType;
      let allowedTypes = [ 'time measurement' ];
      return allowedTypes.includes(type);
    },
    installationCountry () {
      return this.installationObject.country;
    }
  },
  created () {
    this.getInstallation();
  },
  methods: {
    getInstallation () {
      this.loading = true;
      this.axios.get('/Installation/Get?id=' + this.installationId)
        .then((response) => {
          this.installationObject = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>